import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import Stripe from 'stripe';
import { PriceType } from 'voxr-shared';

@Component({
  selector: 'app-price',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './price.component.html'
})
export class PriceComponent {
  @Input() type!: PriceType;
  @Input({ required: true }) price?: Stripe.Price;
  @Input() max?: number;
  @Input() icon?: string;
}
