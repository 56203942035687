@if(plan){
@switch (mode) {
@case ('tag') {<div class="uw-tag uw-tag-info" [matTooltip]="plan.description">{{plan.name}}</div>}
@default{
<mat-card class="tw-h-full">
  <mat-card-header>
    <mat-card-title>{{plan.name}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="tw-flex tw-flex-col tw-gap-3">
      <div class="text-secondary">
        {{plan.description}}
      </div>
      <div>
        <div class="tw-flex tw-w-full tw-gap-1">
          <strong class="tw-me-auto"><app-price [price]="plan.planPrice" icon="pi pi-check text-success" type="plan" /></strong>
          @if(!plan.isPublic) {<div class="uw-tag uw-tag-warning">Exclusive Plan</div>}
          @if(plan.isMaster) {<div class="uw-tag uw-tag-info">Master Plan</div>}
        </div>
      </div>
      <mat-divider />
      <app-price [price]="plan.workspacePrice" [max]="plan.maxWorkspace" icon="pi pi-check text-success"
        type="workspace" />
      <app-price [price]="plan.numberPrice" [max]="plan.maxNumber" icon="pi pi-check text-success" type="number" />
      <app-price [price]="plan.minutesPrice" [max]="plan.maxMinutes" icon="pi pi-check text-success" type="minutes" />
      <app-price [price]="plan.campaignPrice" [max]="plan.maxCampaign" icon="pi pi-check text-success"
        type="campaign" />
      <app-price [price]="plan.memberPrice" [max]="plan.maxMember" icon="pi pi-check text-success" type="member" />
    </div>
  </mat-card-content>
  <mat-card-actions>
    <ng-content select="[footer]"></ng-content>
  </mat-card-actions>
</mat-card>
}
}
}